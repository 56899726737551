import axios from '@axios'

function list(params = null) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios.get('api/people', options).then(response => ({
    item: response.data['hydra:member'],
    totalItems: response.data['hydra:totalItems'],
  }))
}

function get(params = null, id) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .get(`api/people/${id}`, options)
    .then(response => ({
      item: response.data['hydra:member'],
      totalItems: response.data['hydra:totalItems'],
    }))
}

function add(params = null, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .post('api/people', item, options)
    .then(response => ({
      item: response.data,
    }))
}

function user(params = null, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .post('api/users', item, options)
    .then(response => ({
      item: response.data,
    }))
}

function mail(params = null, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .post('mail', item, options)
    .then(response => ({
      item: response.data,
    }))
}

function addRang(params = null, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .post('api/people/update/rang', item, options)
    .then(response => ({
      item: response.data,
    }))
}

async function update(params = null, id, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }
  console.log(item)
  const body = item
  // eslint-disable-next-line no-prototype-builtins
  if (item.hasOwnProperty('file') && item.file !== '') {
    const response = await this.upload(item.file)
    console.log(response)
    body.photo = response['@id']
  } else {
    delete body.photo
  }

  return axios
    .put(`api/people/${id}`, body, options)
    .then(response => ({
      item: response.data,
    }))
}

async function updateUser(params = null, id, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .put(`api/users/${id}`, item, options)
    .then(response => ({
      item: response.data,
    }))
}

function updateByItem(params = null, id, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .patch(`api/people/${id}`, item, options)
    .then(response => ({
      item: response.data,
    }))
}

function destroy(params = null, id) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios.delete(`api/people/${id}`, options).then(() => ({
    message: 'success',
  }))
}

function getCurrentUser(params = null) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .get('/api/me', options)
    .then(response => ({
      item: response.data,
    }))
}

function upload(image) {
  const bodyFormData = new FormData()
  bodyFormData.append('file', image)

  return axios
    .post('api/media', bodyFormData)
    .then(response => response.data)
}

export default {
  list,
  updateUser,
  get,
  getCurrentUser,
  add,
  user,
  update,
  updateByItem,
  destroy,
  upload,
  mail,
  addRang,
}
