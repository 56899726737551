import service from '../services/etat_services'

export default {
  namespaced: true,
  state: {
    list: [],
    item: null,
    totalItems: 0,
    loading: false,
  },
  getters: {
    list: state => state.list,
    totalItems: state => state.totalItems,
    item: state => state.item,
    loading: state => state.loading,
    length: state => state.list.length,
  },
  mutations: {
    SET_LIST(state, val) {
      state.list = val
    },
    SET_LOADING(state, val) {
      state.loading = val
    },
    SET_RESOURCE(state, val) {
      state.item = val
    },
    SET_TOTAL(state, val) {
      state.totalItems = val
    },
    SET_ADD_LIST(state, val) {
      state.list.push(val)
    },
    INCREMENT_TOTAL(state, val) {
      state.totalItems += val
    },
    UPDATE_ITEM_LIST(state, val) {
      state.list.splice(state.list.map(e => e.id).indexOf(val.id), 1, val.item)
    },
    DESTROY_ITEM_LIST(state, val) {
      state.list.splice(state.list.map(e => e.id).indexOf(val), 1)
    },
  },
  actions: {
    list({ commit }, payload) {
      console.log(payload)
      commit('SET_LOADING', true)
      return service.list(payload.params)
        .then(({ item, totalItems }) => {
          commit('SET_LIST', item)
          commit('SET_TOTAL', totalItems)
          commit('SET_LOADING', false)
        })
    },

    get({ commit }, payload) {
      return service.get(payload.params, payload.id)
        .then(({ item, totalItems }) => {
          commit('SET_RESOURCE', item)
          commit('SET_TOTAL', totalItems)
        })
    },

    add({ commit }, payload) {
      return service.add(payload.params, payload.item)
        .then(data => {
          commit('SET_ADD_LIST', data.item)
          commit('INCREMENT_TOTAL', 1)
        })
    },

    update({ commit }, payload) {
      return service.update(payload.params, payload.id, payload.item)
        .then(data => {
          commit('UPDATE_ITEM_LIST', { id: payload.id, item: data.item })
        })
    },

    destroy({ commit }, payload) {
      return service.destroy(payload.params, payload.id).then(() => {
        commit('DESTROY_ITEM_LIST', payload.id)
      })
    },
  },
}
