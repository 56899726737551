import axios from '@axios'

function list(params = null) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios.get('api/scientists', options).then(response => ({
    item: response.data['hydra:member'],
    totalItems: response.data['hydra:totalItems'],
  }))
}

function get(params = null, id) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .get(`api/scientists/${id}`, options)
    .then(response => ({
      item: response.data['hydra:member'],
      totalItems: response.data['hydra:totalItems'],
    }))
}

function add(params = null, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .post('api/scientists', item, options)
    .then(response => ({
      item: response.data,
    }))
}
function mail(params = null, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .post('mail', item, options)
    .then(response => ({
      item: response.data,
    }))
}

function addRang(params = null, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .post('api/scientists/update/rang', item, options)
    .then(response => ({
      item: response.data,
    }))
}

function update(params = null, id, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .put(`api/scientists/${id}`, item, options)
    .then(response => ({
      item: response.data,
    }))
}

function updateByItem(params = null, id, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .patch(`api/scientists/${id}`, item, options)
    .then(response => ({
      item: response.data,
    }))
}

function destroy(params = null, id) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios.delete(`api/scientists/${id}`, options).then(() => ({
    message: 'success',
  }))
}

function upload(image) {
  const bodyFormData = new FormData()
  bodyFormData.append('file', image)

  return axios
    .post('api/media', bodyFormData)
    .then(response => response.data)
}

export default {
  list,
  get,
  add,
  update,
  updateByItem,
  destroy,
  upload,
  mail,
  addRang,
}
