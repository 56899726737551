import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import annonces from './modules/annonce_modules'
import demandes from './modules/demande_modules'
import etats from './modules/etat_modules'
import mails from './modules/mail_modules'
import pays from './modules/pays_modules'
import peoples from './modules/people_modules'
import scientists from './modules/scientist_modules'
import informations from './modules/information_modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    annonces,
    demandes,
    informations,
    etats,
    mails,
    pays,
    peoples,
    scientists,
  },
  strict: process.env.DEV,
})
