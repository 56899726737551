import Vue from 'vue'
import VueRouter from 'vue-router'
// import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/annonces',
      name: 'annonces',
      component: () => import('@/views/annonces/index.vue'),
      meta: {
        pageTitle: 'Annonces',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        resource: 'manage',
        action: 'all',
      },
    },
    {
      path: '/demandes',
      name: 'demandes',
      component: () => import('@/views/demandes/index.vue'),
      meta: {
        pageTitle: 'Demandes',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        resource: 'manager',
        action: 'read',
      },
    },
    {
      path: '/informations',
      name: 'information',
      component: () => import('@/views/informations/index.vue'),
      meta: {
        pageTitle: 'Demandes de modification',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        resource: 'manager',
        action: 'read',
      },
    },
    {
      path: '/informations/detail/:id',
      name: 'information-details',
      component: () => import('@/views/informations/showInformationDetail.vue'),
      meta: {
        pageTitle: 'Détail d\'une modification',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        resource: 'manager',
        action: 'read',
      },
    },
    {
      path: '/mail',
      name: 'mail',
      component: () => import('@/views/mail/index.vue'),
      meta: {
        pageTitle: 'Mail',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        resource: 'manage',
        action: 'all',
      },
    },
    {
      path: '/scientists',
      name: 'scientists',
      component: () => import('@/views/scientists/index.vue'),
      meta: {
        pageTitle: 'Scientists',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        resource: 'manage',
        action: 'all',
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/index.vue'),
      meta: {
        pageTitle: 'Utilisateurs',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        resource: 'manage',
        action: 'all',
      },
    },
    {
      path: '/users/edit',
      name: 'usersEdit',
      component: () => import('@/views/users/UserEditTabAccount.vue'),
      meta: {
        pageTitle: 'Utilisateurs',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        resource: 'read',
        action: 'user',
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      // eslint-disable-next-line import/no-unresolved
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// eslint-disable-next-line consistent-return
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!isLoggedIn) {
    console.log(to)
    if (to.name === 'login' || to.name === 'register') {
      return next()
    }
    return next({ name: 'login' })
  }
  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })
  // }

  // Redirect if logged in
  if (isLoggedIn) {
    // const userData = getUserData()
    if (to.name === 'login') {
      return next({ name: 'home' })
    }
    return next()
  }

  // return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
