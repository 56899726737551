import axios from '@axios'

function list(params = null) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios.get('api/annonces', options).then(response => ({
    item: response.data['hydra:member'],
    totalItems: response.data['hydra:totalItems'],
  }))
}

function get(params = null, id) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .get(`api/annonces/${id}`, options)
    .then(response => ({
      item: response.data['hydra:member'],
      totalItems: response.data['hydra:totalItems'],
    }))
}

function upload(image) {
  const bodyFormData = new FormData()
  bodyFormData.append('file', image)

  return axios
    .post('api/media', bodyFormData)
    .then(response => response.data)
}

async function add(params = null, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }
  const body = item
  const reponse = await upload(item.file)
  console.log(reponse)
  body.cover = reponse['@id']
  return axios
    .post('api/annonces', body, options)
    .then(response => ({
      item: response.data,
    }))
}
function mail(params = null, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .post('mail', item, options)
    .then(response => ({
      item: response.data,
    }))
}

function addRang(params = null, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .post('api/annonces/update/rang', item, options)
    .then(response => ({
      item: response.data,
    }))
}

function update(params = null, id, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .put(`api/annonces/${id}`, item, options)
    .then(response => ({
      item: response.data,
    }))
}

function updateByItem(params = null, id, item) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios
    .patch(`api/annonces/${id}`, item, options)
    .then(response => ({
      item: response.data,
    }))
}

function destroy(params = null, id) {
  let options = null
  if (params !== null) {
    options = { params }
  }

  return axios.delete(`api/annonces/${id}`, options).then(() => ({
    message: 'success',
  }))
}

export default {
  list,
  get,
  add,
  update,
  updateByItem,
  destroy,
  upload,
  mail,
  addRang,
}
